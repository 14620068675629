import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import Button from 'ui-kit/button/button';
import Spinner from 'ui-kit/spinner/spinner';

import BirdiPrice from 'components/birdi-price/birdi-price.component';
import Disclaimer from 'components/disclaimer/disclaimer.component';

import { FailureUpdateCartModalContent } from 'pages/secure/cart/intra-page-items/_cart-update-modal-item';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { cancelOrderLine, getCartRoutine } from 'state/cart/cart.routines';

import { formatPercent, formatPrice } from 'schema/price.schema';

import { ExtendedRefillRxs } from 'types/order-prescription';

import { EXPEDITED_SHIPPING_COST, hasAdjudicatedPrice } from 'util/cart';
import { getPhoneNumber } from 'util/globalVariables';
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';
import { safeParseFloat } from 'util/number';

import { PrescriptionInformationProps } from './types';

export const PrescriptionInformation = ({
    t,
    fullCart,
    prescriptions,
    accountHasInsurance,
    setCartPageTracked,
    orderConfirmation = false
}: PrescriptionInformationProps) => {
    const dispatch = useDispatch();
    const [isRemoveBusy, setIsRemoveBusy] = useState(false);
    const cartItems = fullCart.extendedRefillRxs;

    function formatBirdiSavings(item: ExtendedRefillRxs | undefined) {
        if (item) {
            const savingsAsDollar = safeParseFloat(item.awpPrice) - safeParseFloat(item.birdiPrice);
            const savingsAsPercent = formatPercent(savingsAsDollar, item.awpPrice);
            return `${formatPrice(savingsAsDollar)} (${t('pages.cart.percentOff', { percentOff: savingsAsPercent })})`;
        } else {
            return '';
        }
    }

    const showCartError = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.cart')} />,
                ctas: [
                    {
                        label: t('modals.updateCart.labels.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'UpdateCartError'
                    }
                ]
            })
        );
    };

    return (
        <>
            <Spinner isVisible={isRemoveBusy} t={t} />
            <Container className="cart-prescription" fluid>
                {cartItems?.map((item) => {
                    const currentPrescription = prescriptions.find((obj) => {
                        return obj.rxNumber === item.rxNumber;
                    });
                    return (
                        <Row key={item.rxNumber} className="mt-4 pb-2 pb-md-4 cart-prescription--item">
                            <Col className="d-flex align-items-stretch flex-column">
                                <h3 className="cart-prescription--header">
                                    {currentPrescription?.dispensedProductName}
                                </h3>
                                <div className="cart-prescription--product-info">
                                    {currentPrescription?.fillQuantity && (
                                        <div>
                                            {t('pages.cart.quantity', {
                                                fillQuantity: currentPrescription?.fillQuantity
                                            })}
                                        </div>
                                    )}
                                    {(currentPrescription?.dispensedProductStrength ||
                                        currentPrescription?.dispensedProductUnitOfMeasure) && (
                                        <div>
                                            {currentPrescription?.dispensedProductStrength}
                                            {currentPrescription?.dispensedProductUnitOfMeasure}
                                        </div>
                                    )}
                                    {currentPrescription?.dispensedProductDosageForm && (
                                        <div>{currentPrescription?.dispensedProductDosageForm}</div>
                                    )}
                                </div>
                                {currentPrescription?.autoRefillEnabled && (
                                    <div className="cart-prescription--auto-refill-label">
                                        <span>{t('pages.cart.rxSetToAutoRefill')}</span>
                                    </div>
                                )}
                            </Col>
                            <Col sm={12} lg={'auto'} className="text-left text-lg-right mt-3 mt-lg-0">
                                <div className="d-flex flex-column-reverse flex-lg-column">
                                    <div className="mt-2 mb-2 mt-lg-0">
                                        <Button
                                            type="button"
                                            variant="text"
                                            className="p-0 mb-3 left-n1 ml-lg-auto btn-remove"
                                            label={t('pages.cart.remove')}
                                            disabled={isRemoveBusy}
                                            onClick={() => {
                                                setIsRemoveBusy(true);
                                                setCartPageTracked(false);
                                                dispatch(
                                                    cancelOrderLine({
                                                        rxNumber: item.rxNumber,
                                                        onSuccess: () => {
                                                            setIsRemoveBusy(false);
                                                            TrackCheckoutStep({
                                                                stepName: 'remove',
                                                                step: '1',
                                                                cart: fullCart,
                                                                prescriptions: prescriptions,
                                                                t: t,
                                                                shippingCost: fullCart?.orderHeader?.orderHighPriority
                                                                    ? `${EXPEDITED_SHIPPING_COST}`
                                                                    : '0',
                                                                accountHasInsurance: accountHasInsurance
                                                            });
                                                            if (cartItems.length <= 1) {
                                                                dispatch(getCartRoutine.trigger());
                                                            }
                                                        },
                                                        onFailure: () => {
                                                            showCartError();
                                                            setIsRemoveBusy(false);
                                                        }
                                                    })
                                                );
                                            }}
                                            dataGAFormName="addMorePrescriptions"
                                            trashIcon
                                        />
                                    </div>
                                    {(hasAdjudicatedPrice(item, currentPrescription) ||
                                        (!hasAdjudicatedPrice(item, currentPrescription) &&
                                            item.hasKnownPrice &&
                                            item.showBirdiPricing)) && (
                                        <React.Fragment>
                                            <div>
                                                <div>
                                                    <h3 className="cart-total--title">
                                                        <span>
                                                            {formatPrice(
                                                                item.isUsingBirdiPrice
                                                                    ? item.birdiPrice
                                                                    : item.patientCopay
                                                            )}
                                                        </span>
                                                    </h3>
                                                </div>
                                                <div className="mb-3">
                                                    <div className="h6 cart-total--description mr-0 ">
                                                        <BirdiPrice
                                                            accountHasInsurance={accountHasInsurance}
                                                            insuredText={t('pages.cart.rxItemTotal')}
                                                            showIfInsured={item.showBirdiPricing}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                    {!hasAdjudicatedPrice(item, currentPrescription) && item.hasKnownPrice && (
                                        <React.Fragment>
                                            {item.showStrikeThruPricing && (
                                                <React.Fragment>
                                                    <div>
                                                        <div>
                                                            <div className="cart-total--strikethrough">
                                                                {t('pages.cart.standardPrice')}{' '}
                                                                <span className="text-decoration-line-through">
                                                                    {formatPrice(item.awpPrice)}
                                                                </span>
                                                            </div>
                                                            <div className="cart-total--strikethrough">
                                                                {t('pages.cart.youSave')} {formatBirdiSavings(item)}
                                                            </div>
                                                            <h3 className="cart-total--title mt-2">
                                                                {formatPrice(item?.birdiPrice)}
                                                            </h3>
                                                        </div>
                                                        <div className="mb-3">
                                                            <div className="h6 cart-total--description mr-0 ">
                                                                <BirdiPrice
                                                                    accountHasInsurance={accountHasInsurance}
                                                                    insuredText={t('pages.cart.rxItemTotal')}
                                                                    showIfInsured={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            </Col>
                            {!item.hasKnownPrice && (
                                <Col className="mt-3" sm={12} lg={'auto'}>
                                    <Disclaimer
                                        disclaimerText={t(
                                            accountHasInsurance
                                                ? 'pages.cart.rxItemErrorMessage'
                                                : 'pages.cart.rxItemErrorMessageDiscount'
                                        )}
                                    />
                                </Col>
                            )}
                            {item.showDisclaimer && item.disclaimerTranslationKey && (
                                <Col className="mt-3" sm={12} lg={'auto'}>
                                    <Disclaimer
                                        disclaimerText={t(item.disclaimerTranslationKey, {
                                            phoneNumber: getPhoneNumber({})
                                        })}
                                    />
                                </Col>
                            )}
                        </Row>
                    );
                })}
                <Row>
                    <Col className="d-flex justify-content-md-end mt-3">
                        <Button
                            type="button"
                            variant="text"
                            className="p-0"
                            label={t('pages.cart.addMorePrescriptions')}
                            onClick={() => {
                                navigate('/secure/medicine-cabinet');
                            }}
                            dataGAFormName="addMorePrescriptions"
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
};
