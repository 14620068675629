import { graphql, navigate } from 'gatsby';
import { ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

//  misc ui-kit
import Button from 'ui-kit/button/button';
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';
import LoadingMessage from 'ui-kit/loading-message/loading-message';
import Spinner from 'ui-kit/spinner/spinner';

import { CartShipping } from 'components/cart/cart-shipping.component';
import { CartTotal } from 'components/cart/cart-total/cart-total.component';
import { PrescriptionInformation } from 'components/cart/prescription-information.component';
//  workflow
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import { PrescriptionContentSectionHeader } from 'components/prescription-content-section-header';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

import { accountHasInsuranceSelector, accountPlansSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { cartUpdateExpeditedShippingRoutine } from 'state/cart/cart.routines';
import { getCartRoutine } from 'state/cart/cart.routines';
// cart
import { cartIsBusySelector, cartOrderBillShipMethodSelector, cartSelector } from 'state/cart/cart.selectors';
import { ExtendedCartObjectPayload } from 'state/cart/cart.services';
import { drugDiscountPriceRoutine } from 'state/drug/drug.routines';
import { drugSelector } from 'state/drug/drug.selectors';
//  state
import { medicineCabinetGetAllPrescriptions } from 'state/medicine-cabinet/medicine-cabinet.routines';
import { medicineCabinetPrescriptionsSelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';
import { PrescriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';

import { DEFAULT_SHIPPING_ID, EXPEDITED_SHIPPING_COST, EXPEDITED_SHIPPING_ID, processCart } from 'util/cart';
// analytics
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';

import './cart.style.scss';
import { FailureUpdateCartModalContent } from './intra-page-items/_cart-update-modal-item';

const Cart = ({ data }: { data: GatsbyTypes.CartDataQuery }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const prescriptionsObject = useSelector(medicineCabinetPrescriptionsSelector);
    const accountPlans = useSelector(accountPlansSelector);
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const cartObject = useSelector(cartSelector);
    const [extendedCartObject, setExtendedCartObject] = useState<ExtendedCartObjectPayload | undefined>(undefined);
    const shipMethodId = useSelector(cartOrderBillShipMethodSelector);
    const cartIsBusy = useSelector(cartIsBusySelector);
    const [cartIsEmpty, setCartIsEmpty] = useState(true);
    const [hasUnknownPrice, setHasUnknownPrice] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentShippingPrice, setCurrentShippingPrice] = useState<number>(0);
    const showLoading = extendedCartObject === undefined;
    const { drugDiscountPrices } = useSelector(drugSelector);
    const [drugDiscountPricesFetched, setDrugDiscountPricesFetched] = useState(false);
    const [cartPageTracked, setCartPageTracked] = useState(false);

    useEffect(() => {
        dispatch(getCartRoutine.trigger());
    }, [dispatch]);

    useEffect(() => {
        dispatch(medicineCabinetGetAllPrescriptions({ showNewRxModal: true }));
    }, []);

    const getDrugDiscountPrice = async (birdiPricePrescriptions: PrescriptionObjectPayload[]) =>
        new Promise((resolve, reject) => {
            // do anything here
            dispatch(
                drugDiscountPriceRoutine.trigger({
                    prescriptions: birdiPricePrescriptions,
                    forceBirdiInsurance: true,
                    location: 'Cart',
                    onSuccess: () => {
                        resolve('success');
                    }
                })
            );
        });

    const trackCartStep1 = () => {
        if (!cartPageTracked) {
            TrackCheckoutStep({
                stepName: 'checkout',
                step: '1',
                cart: extendedCartObject,
                prescriptions: prescriptionsObject,
                t: t,
                shippingCost: cartObject?.orderHeader?.orderHighPriority ? `${EXPEDITED_SHIPPING_COST}` : '0',
                accountHasInsurance: accountHasInsurance
            });
            setCartPageTracked(true);
        }
    };

    useEffect(() => {
        const birdiPricePrescriptions: PrescriptionObjectPayload[] = [];
        const cartItemsObject = extendedCartObject?.extendedRefillRxs;

        if (cartItemsObject?.length) {
            if (!drugDiscountPricesFetched && accountHasInsurance && ALLOW_INSURED_BIRDI_PRICE) {
                // Get The Birdi Price
                cartItemsObject.forEach((item) => {
                    const currentPrescription = prescriptionsObject.find((obj) => {
                        return obj.rxNumber === item.rxNumber;
                    });
                    if (currentPrescription) {
                        birdiPricePrescriptions.push(currentPrescription);
                    }
                });
                setDrugDiscountPricesFetched(true); // Before .then, since error would cause a loop
                getDrugDiscountPrice(birdiPricePrescriptions).then(() => {
                    trackCartStep1();
                });
            } else {
                if (prescriptionsObject.length > 0 && accountPlans && accountPlans.length > 0) {
                    trackCartStep1();
                }
            }
            setCartIsEmpty(false);
        } else {
            setCartIsEmpty(true);
            if (extendedCartObject) {
                trackCartStep1();
            }
        }

        setHasUnknownPrice(extendedCartObject?.itemHasUnknownPrice ?? false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extendedCartObject]);

    useEffect(() => {
        if (cartObject) {
            const extendedCart = processCart(
                cartObject,
                accountHasInsurance,
                prescriptionsObject,
                drugDiscountPrices,
                accountPlans
            );
            setExtendedCartObject(extendedCart);
            setInitialOrderPrice(extendedCart.orderTotal);
            setCurrentShippingPrice(extendedCart?.orderHeader?.orderHighPriority ? EXPEDITED_SHIPPING_COST : 0);
        }
    }, [cartObject, accountHasInsurance, prescriptionsObject, drugDiscountPrices, accountPlans]);

    const [initialOrderPrice, setInitialOrderPrice] = useState(extendedCartObject?.orderTotal);

    const showShippingError = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.shippingOption')} />,
                ctas: [
                    {
                        label: t('modals.updateCart.labels.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'UpdateCartError'
                    }
                ]
            })
        );
    };

    const handleExpeditedShippingClick = () => {
        dispatch(
            cartUpdateExpeditedShippingRoutine({
                orderHighPriority: true,
                shipMethodId: EXPEDITED_SHIPPING_ID,
                onSuccess: () => {
                    dispatch(getCartRoutine.trigger());
                    setCurrentShippingPrice(EXPEDITED_SHIPPING_COST);
                },
                onFailure: () => {
                    showShippingError();
                    dispatch(getCartRoutine.trigger());
                    return false;
                }
            })
        );
    };

    const handleDefaultShippingClick = () => {
        dispatch(
            cartUpdateExpeditedShippingRoutine({
                orderHighPriority: false,
                shipMethodId: DEFAULT_SHIPPING_ID,
                onSuccess: () => {
                    dispatch(getCartRoutine.trigger());
                    setCurrentShippingPrice(0);
                },
                onFailure: () => {
                    showShippingError();
                    dispatch(getCartRoutine.trigger());
                    return false;
                }
            })
        );
    };

    return (
        <WorkflowLayout
            className="cart-workflow"
            backgroundImage={data.backgroundImage}
            metaData={{ nodeTitle: t('pages.cart.title') }}
        >
            <WorkflowLayoutFormWrapper
                eyebrowText={t('pages.cart.eyebrowText')}
                title={t('pages.cart.title')}
                className="cart-workflow-layout-form-wrapper"
                icon={<CartIcon className={'header-icon-container'} />}
            >
                <>
                    <Spinner isVisible={cartIsBusy} t={t} />
                    <LoadingMessage isVisible={showLoading} text={t('pages.cart.loading')} />
                    {!showLoading &&
                        (!cartIsEmpty ? (
                            <>
                                <PrescriptionContentSectionHeader
                                    label={t('pages.cart.prescriptionInformation')}
                                    pricingLabel={t('pages.cart.pricing')}
                                />
                                <PrescriptionInformation
                                    t={t}
                                    fullCart={extendedCartObject}
                                    prescriptions={prescriptionsObject}
                                    accountHasInsurance={accountHasInsurance}
                                    setCartPageTracked={setCartPageTracked}
                                />
                                <PrescriptionContentSectionHeader label={t('pages.cart.shippingOptions')} />
                                <CartShipping
                                    handleDefaultShippingClick={handleDefaultShippingClick}
                                    handleExpeditedShippingClick={handleExpeditedShippingClick}
                                    shipMethodId={shipMethodId}
                                    t={t}
                                />
                                <CartTotal
                                    isInsuranceAccount={accountHasInsurance}
                                    currentShippingPrice={currentShippingPrice}
                                    hasUnknownPrice={hasUnknownPrice}
                                    initialOrderPrice={initialOrderPrice}
                                    t={t}
                                />
                                {hasUnknownPrice && accountHasInsurance && (
                                    <Row className="mt-5">
                                        <Col>
                                            <small>
                                                <sup>*</sup> {t('pages.cart.totalDisclaimer')}
                                            </small>
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col className="d-flex flex-column flex-md-row-reverse justify-content-md-start align-items-center pt-5">
                                        <Button
                                            type="button"
                                            className="cart-submit-button"
                                            label={t('pages.cart.checkout')}
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                setIsSubmitting(true);
                                                navigate('/secure/cart/review');
                                            }}
                                            dataGAFormName="cart"
                                            dataGAFormStepName="ViewCart"
                                        />
                                        <Button
                                            type="button"
                                            variant="text"
                                            className="mt-3 mt-md-0 mr-md-4 p-0"
                                            label={t('pages.cart.viewMedicineCabinet')}
                                            onClick={() => navigate('/secure/medicine-cabinet')}
                                            dataGAFormName="cart"
                                            dataGAFormStepName="ViewCart"
                                        />
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <>
                                <PrescriptionContentSectionHeader label={t('pages.cart.titleEmpty')} />
                                <Container className="cart-prescription" fluid>
                                    <Row>
                                        <Col className="mt-4">
                                            <div>
                                                <Button
                                                    type="button"
                                                    className="sm-full"
                                                    variant="outline-primary"
                                                    label={t('pages.cart.goBack')}
                                                    onClick={() => navigate('/secure/medicine-cabinet')}
                                                    dataGALocation="Your shopping cart is empty."
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </>
                        ))}
                </>
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};

export default Cart;

export const query = graphql`
    query CartData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
