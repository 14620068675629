import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import './index.style.scss';
import { PrescriptionContentSectionHeaderProps } from './types';

export const PrescriptionContentSectionHeader: React.FC<PrescriptionContentSectionHeaderProps> = ({
    label,
    pricingLabel,
    text,
    className = 'cart-review-workflow-layout-content-section-header'
}) => {
    return (
        <Container fluid>
            <Row>
                <Col className={`${className}-col`}>
                    <div className={`${className} mt-5`}>
                        <div className="blue-half-pill" style={{ height: '0.8rem', width: '0.8rem' }} />
                        <h2 className={`h3 mr-auto ${className}-title`}>{label}</h2>
                        {pricingLabel && <div className="d-none d-lg-block mb-1">{pricingLabel}</div>}
                    </div>
                </Col>
            </Row>
            {text && (
                <Row>
                    <Col className="mt-4">{text}</Col>
                </Row>
            )}
        </Container>
    );
};
