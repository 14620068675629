import { ReviewCartTotalProps } from '../order-confirmation/types';
import { Container, Row, Col } from 'react-bootstrap';
import { formatPrice } from 'schema/price.schema';
import ToastBox from 'ui-kit/toast-box/toast-box';

import './cart-total.style.scss';

export const CartTotal = ({
    t,
    hasUnknownPrice,
    initialOrderPrice,
    currentShippingPrice,
    disclaimerText,
    isInsuranceAccount
}: ReviewCartTotalProps) => {
    return (
        <Container>
            <Row>
                <Col>
                    <div className="cart__cart-total">
                        <Container fluid className="cart__cart-total--details">
                            <Row className="cart__cart-total--subtotal">
                                <Col xs={6} className="cart__cart-total--title">
                                    {t('pages.cart.subTotal')}
                                    {hasUnknownPrice && isInsuranceAccount ? <sup>*</sup> : ''}:
                                </Col>
                                <Col xs={6} className="cart__cart-total--price text-right">
                                    {formatPrice(initialOrderPrice)}
                                </Col>
                            </Row>
                            <Row className="cart__cart-total--shipping">
                                <Col xs={6} className="cart__cart-total--title">
                                    {t('pages.cart.shipping')}
                                </Col>
                                <Col xs={6} className="cart__cart-total--price text-right">
                                    {formatPrice(currentShippingPrice)}
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className="cart__cart-total--summary">
                            <Row>
                                <Col xs={6} className="cart__cart-total--title">
                                    {t('pages.cart.estTotal')}
                                    {hasUnknownPrice && isInsuranceAccount ? <sup>*</sup> : ''}:
                                </Col>
                                <Col xs={6} className="cart__cart-total--price text-right">
                                    {formatPrice(String(Number(initialOrderPrice) + Number(currentShippingPrice)))}
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className="d-flex flex-column">
                            {disclaimerText && (
                                <Row className="cart__cart-total--disclaimer">
                                    <ToastBox>{disclaimerText}</ToastBox>
                                </Row>
                            )}
                        </Container>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};
